
* {
    margin: 0;
    /* background-color: grey; */
    /* transition: all 1s; */
}

canvas{
    /* background-color: black; */
    display: block ;
    overflow: hidden;
    height: 100%;
}

#canvasContainer{
    position: absolute;
    border: 1px solid black;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    /* height: calc(100vh - 3px); */
    overflow: hidden;

}

.mainCenterInfoDiv{
    width: 20px;
    height: 100px;
    background-color: wheat;
    border: 1px solid black;
}

.welcomeMessage{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    /* border: 1px solid lightblue; */
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    color: lightgrey;
}
.title-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: 5vw;
    font-family: 'Times New Roman', Times, serif;
}
.startBtn{
    padding: 10px 20px;
    margin-top: 50%;
    background-color: #282828;
    color: #fff;
    border: 1px solid lightblue;
    border-radius: 8px;
    font-size: 1.5rem;
    font-weight: 100;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

/*Hover effect*/
.startBtn:hover {
    background-color: #404040;
    box-shadow: 0px 0px 10px #000;
}

.loading-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    color: blue;
    padding: 20px;
    border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .loading-wrapper {
    width: 80%;
    padding: 10px;
  }
}
